import React, { memo, useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../Components/Common/Header";
import CartSvg from "../Components/UI/CartSvg/CartSvg";
import CategoriesList from "../Components/Shop/CategoriesList";
import GetSwiper from "../Components/Sliders/GetSwiper";
import fetchData from "../Lib/fetchData";
import StuffList from "../Components/Common/StuffList";
import Loader3Packs from "./Loaders/Loader3Packs";
import AnchorBasket from "../Components/Common/AnchorBasket";

const Categories = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isCategoryChanged, setIsCategoryChanged] = useState(false);
  const [isFilterChanged, setIsFilterChanged] = useState(false);
  const [isModelChanged, setIsModelChanged] = useState(false);
  const [isBrandChanged, setIsBrandChanged] = useState(false);
  const [stuffCount, setStuffCount] = useState(12);
  const [filteredStuffList, setFilteredStuffList] = useState([]);
  const [activeCategory, setActiveCategory] = useState(
    searchParams.get("category")
  );
  const [activeFilter, setActiveFilter] = useState(
    searchParams.get("filter") || "all"
  );
  const [activeBrand, setActiveBrand] = useState(
    searchParams.get("brand") || "all"
  );
  const [activeModel, setActiveModel] = useState(
    searchParams.get("model") || "all"
  );
  const [stuffList, setStuffList] = useState([]);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [allBrands, setAllBrands] = useState([]);
  const [isThirdFilter, setIsThirdFilter] = useState(searchParams.get('isThirdFilter') != null);
  const [thirdFilters, setThirdFilters] = useState([]);
  const [activeThirdFilter, setActiveThirdFilter] = useState(searchParams.get('thirdFilter') != null ? JSON.parse(searchParams.get('thirdFilter')) : {});
  const [isDevices, setIsDevices] = useState(searchParams.get('isDevices') != null);
  const [devicesModel, setDevicesModel] = useState(searchParams.get('devicesModel') || 'all');

  const setActThirdFilter = (filter) => {
    setActiveThirdFilter(filter);
  };

  const changeDevicesModel = (mod) => {
    setDevicesModel(mod);
  }

  const CategoriesListMemo = memo(({ activeCategory }) => (
    <CategoriesList
      isFromShop={false}
      activeCategory={activeCategory}
      setActCategory={setActCategory}
    />
  ));

  const setActCategory = useCallback((category) => {
    setIsCategoryChanged(true);
    setActiveCategory(category);
  }, []);

  const setActFilter = useCallback((filter) => {
    setIsFilterChanged(true);
    setActiveFilter(filter);
  }, []);

  const setActBrand = useCallback((brand) => {
    setIsBrandChanged(true);
    setIsDevices('all');
    setDevicesModel('all');
    setActiveBrand(brand);
  }, []);

  const setActModel = useCallback((model) => {
    setIsModelChanged(true);
    setActiveModel(model);
  }, []);

  const changeIsDevices = (isDev) => {
    setIsDevices(isDev);
    setDevicesModel('all');
  }

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight) {
        setStuffCount((prevState) => prevState + 12);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setStuffCount(12);
    setStuffList([]);
    setIsLoading(true);
    searchParams.set("category", activeCategory);
    setSearchParams(searchParams);

    if (isCategoryChanged) {
      setActiveFilter("all");
      setActiveBrand("all");
      setActiveModel("all");
      searchParams.delete("filter");
      searchParams.delete("brand");
      searchParams.delete("model");
      setSearchParams(searchParams);
    }

    const getStuff = async () => {
      try {
        const response = await fetchData(
          `/stuff/filter?category=${activeCategory}`,
          "GET"
        );

        if (response.status === 200) {
          const stuff = await response.json();
          setStuffList(stuff);
          let activeF = activeFilter;
          let activeB = activeBrand;
          let activeM = activeModel;
          if (isCategoryChanged) {
            activeF = "all";
            activeB = "all";
            activeM = "all";
          }

          const newBrands = await fetchData(
            "/stuff/brands/" + activeCategory,
            "GET"
          ).then((res) => res.json());
          setAllBrands(newBrands);

          if (activeCategory === "snus") {
            setFilteredStuffList(
              stuff.filter(
                (item) =>
                  activeF === "all" ||
                  (activeF === "tobacco" &&
                    item.isTobacco &&
                    (item.brand === activeB || activeB === "all")) ||
                  (activeF === "non-tobacco" &&
                    !item.isTobacco &&
                    (item.brand === activeB || activeB === "all"))
              )
            );

            if (activeFilter === "tobacco") {
              setBrands([
                ...new Set(
                  newBrands
                    .filter((item) => item.isTobacco)
                    .map((item) => item.brand)
                ),
              ]);
            } else if (activeFilter === "non-tobacco") {
              setBrands([
                ...new Set(
                  newBrands
                    .filter((item) => !item.isTobacco)
                    .map((item) => item.brand)
                ),
              ]);
              setActiveThirdFilter(searchParams.get('thirdFilter') != null ? JSON.parse(searchParams.get('thirdFilter')) : {})
            } else {
              setBrands([...new Set(newBrands.map((item) => item.brand))]);
            }
          } else {
            setBrands([...new Set(newBrands.map((item) => item.brand))]);
            setFilteredStuffList(
              stuff.filter(
                (item) =>
                  (item.model === activeM || activeM === "all") &&
                  (item.brand === activeB || activeB === "all")
              )
            );
            setDevicesModel(searchParams.get('devicesModel') || 'all');
          }
          const mdls = await fetchData(
            `/stuff/models/${activeCategory}/${activeB}?isDevice=${isDevices ? '1' : '0'}`,
            "GET"
          ).then((res) => res.json());
          setModels(mdls.map((model) => model.model));
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching stuff:", error);
      }
    };

    getStuff();
  }, [activeCategory]);

  useEffect(() => {
    setStuffCount(12);

    if (activeCategory === "snus" && activeFilter !== "all") {
      if (isFilterChanged) setActiveBrand("all");

      if (activeFilter === "tobacco") {
        setBrands([
          ...new Set(
            allBrands.filter((item) => item.isTobacco).map((item) => item.brand)
          ),
        ]);
      } else if (activeFilter === "non-tobacco") {
        setBrands([
          ...new Set(
            allBrands
              .filter((item) => !item.isTobacco)
              .map((item) => item.brand)
          ),
        ]);
      } else {
        setBrands([...new Set(allBrands.map((item) => item.brand))]);
        if (activeBrand !== "all") {
          setFilteredStuffList(
            stuffList.filter(
              (item) =>
                (item.model === activeModel || activeModel === "all") &&
                item.brand === activeBrand
            )
          );
        }
      }
    }

    searchParams.set("filter", activeFilter);
    setSearchParams(searchParams);

    setFilteredStuffList(
      stuffList.filter(
        (item) =>
          activeFilter === "all" ||
          (activeFilter === "tobacco" &&
            item.isTobacco &&
            (item.brand === activeBrand || activeBrand === "all")) ||
          (activeFilter === "non-tobacco" &&
            !item.isTobacco &&
            (item.brand === activeBrand || activeBrand === "all"))
      )
    );
  }, [activeFilter]);

  useEffect(() => {
    setStuffCount(12);

    if (activeCategory !== "snus") {
      if (isBrandChanged) setActiveModel("all");

      const mdls = fetchData(
        `/stuff/models/${activeCategory}/${activeBrand}?isDevice=${isDevices ? '1' : '0'}`,
        "GET"
      )
        .then((res) => res.json())
        .then((mdls) => {
          setModels(mdls.map((model) => model.model));
        });
      setFilteredStuffList(
        stuffList.filter(
          (item) => item.brand === activeBrand || activeBrand === "all"
        )
      );
    } else {
      if (activeFilter === "tobacco") {
        setFilteredStuffList(
          stuffList.filter(
            (item) =>
              (item.brand === activeBrand || activeBrand === "all") &&
              item.isTobacco
          )
        );
      } else if (activeFilter === "non-tobacco") {
        setFilteredStuffList(
          stuffList.filter(
            (item) =>
              (item.brand === activeBrand || activeBrand === "all") &&
              !item.isTobacco
          )
        );
      } else {
        setFilteredStuffList(
          stuffList.filter(
            (item) => item.brand === activeBrand || activeBrand === "all"
          )
        );
      }
    }

    searchParams.set("brand", activeBrand);
    setSearchParams(searchParams);
    if (activeBrand === "ARQA" && activeFilter === "non-tobacco") {
      setIsThirdFilter(true);
      searchParams.delete('thirdFilter');
      searchParams.set("isThirdFilter", "1");
      setSearchParams(searchParams);
      setThirdFilters([
        {
          brand: "arqa",
          name: "ARQA 70 MG",
          nicotineContent: 70,
        },
        {
          brand: "arqa",
          name: "ARQA 70 MG SLIM",
          nicotineContent: 70,
          additionFilter: "slim",
        },
        {
          brand: "arqa",
          name: "ARQA 100 MG",
          nicotineContent: 100,
        },
        {
          brand: "arqa",
          name: "ARQA 100 MG SLIM",
          nicotineContent: 100,
          additionFilter: "slim",
        },
        {
          brand: "arqa",
          name: "ARQA CS 120 MG",
          nicotineContent: 120,
          additionFilter: "cs",
        },
        {
          brand: "arqa",
          name: "ARQA DOTA 120 MG",
          nicotineContent: 120,
          additionFilter: "dota",
        },
        {
          brand: "arqa",
          name: "ARQA PREMIUM 150 MG",
          nicotineContent: 150
        },
        {
          brand: 'arqa',
          name: 'ARQA LIGHT 30 MG SLIM',
          nicotineContent: 30,
          additionFilter: 'light'
        }
      ]);
    } else if (activeBrand === "FAFF." && activeFilter === "non-tobacco") {
      setIsThirdFilter(true);
      searchParams.delete('thirdFilter');
      searchParams.set("isThirdFilter", "1");
      setSearchParams(searchParams);
      setThirdFilters([
        {
          brand: "faff.",
          name: "65 MG",
          nicotineContent: 65,
        },
        {
          brand: "faff.",
          name: "75 MG",
          nicotineContent: 75,
        },
        {
          brand: "faff.",
          name: "100 MG",
          nicotineContent: 100,
        },
        {
          brand: "faff.",
          name: "150 MG",
          nicotineContent: 150,
        },
      ]);
    } else if (activeBrand === "DRYMOST" && activeFilter === "non-tobacco") {
      setIsThirdFilter(true);
      searchParams.delete('thirdFilter');
      searchParams.set("isThirdFilter", "1");
      setSearchParams(searchParams);
      setThirdFilters([
        {
          brand: "drymost",
          name: "25 MG",
          nicotineContent: 25,
        },
        {
          brand: "drymost",
          name: "150 MG",
          nicotineContent: 150,
        },
        {
          brand: "drymost",
          name: "200 MG",
          nicotineContent: 200,
        },
      ]);
    } else if (activeBrand === "KASTA" && activeFilter === "non-tobacco") {
      setIsThirdFilter(true);
      searchParams.delete('thirdFilter');
      searchParams.set("isThirdFilter", "1");
      setSearchParams(searchParams);
      setThirdFilters([
        {
          brand: "kasta",
          name: "101 MG",
          nicotineContent: 101,
        },
        {
          brand: "kasta",
          name: "ICED OUT",
          isIcedOut: true,
        },
        {
          brand: "kasta",
          name: "150 MG",
          nicotineContent: 150,
        },
        {
          brand: "kasta",
          name: "DOTA 120 MG",
          nicotineContent: 120,
        },
      ]);
    } else if (activeBrand === "SUPEROUSE" && activeFilter === "non-tobacco") {
      setIsThirdFilter(true);
      searchParams.delete('thirdFilter');
      searchParams.set("isThirdFilter", "1");
      setSearchParams(searchParams);
      setThirdFilters([
        {
          brand: 'superouse',
          name: '20 MG',
          nicotineContent: 20,
          additionFilter: 'superouse 20 mg'
        },
        {
          brand: 'superouse',
          name: 'MOIST',
          nicotineContent: 16,
          additionFilter: 'moist'
        },
        {
          brand: 'superouse',
          name: '8 MG',
          nicotineContent: 8
        },
        {
          brand: 'superouse',
          name: '10 MG',
          nicotineContent: 10
        },
        {
          brand: 'superouse',
          name: '12 MG',
          nicotineContent: 12
        },
        {
          brand: 'superouse',
          name: '14 MG',
          nicotineContent: 14
        },
        {
          brand: 'superouse',
          name: '17 MG',
          nicotineContent: 17
        }
      ]);
    } else if (activeBrand === "EGP" && activeFilter === "non-tobacco") {
      setIsThirdFilter(true);
      searchParams.delete('thirdFilter');
      searchParams.set("isThirdFilter", "1");
      setSearchParams(searchParams);
      setThirdFilters([
        {
          brand: 'egp',
          name: '6 MG',
          nicotineContent: 12
        },
        {
          brand: 'egp',
          name: '9 MG',
          nicotineContent: 18
        },
        {
          brand: 'egp',
          name: '14 MG',
          nicotineContent: 28
        },
        {
          brand: 'egp',
          name: '20 MG',
          nicotineContent: 40
        },
      ]);
    } else {
      setIsThirdFilter(false);
      searchParams.delete("isThirdFilter");
      setSearchParams(searchParams);
    }
  }, [activeBrand, isDevices]);

  useEffect(() => {
    if(activeCategory !== 'devices') return;
    console.log(isDevices)
    if(isDevices === 'all') {
      setFilteredStuffList(stuffList.filter(stuff => stuff.brand === activeBrand || activeBrand === 'all'));
    }
    else if(isDevices === true) {
      searchParams.set('isDevices', "1");
      setSearchParams(searchParams)
      if(devicesModel === 'all') {
        setFilteredStuffList(stuffList.filter(stuff => stuff.brand === activeBrand && stuff.type.toLowerCase() === 'pod kit'));
      } else {
        setFilteredStuffList(stuffList.filter(stuff => stuff.brand === activeBrand && stuff.type.toLowerCase() === 'pod kit' && stuff.model === devicesModel));
      }
      searchParams.set('devicesModel', devicesModel);
      setSearchParams(searchParams)
    } else {
      searchParams.delete('isDevices');
      setSearchParams(searchParams)
      if(devicesModel === 'all') {
        setFilteredStuffList(stuffList.filter(stuff => stuff.brand === activeBrand && stuff.type.toLowerCase() !== 'pod kit'));
      } else {
        setFilteredStuffList(stuffList.filter(stuff => stuff.brand === activeBrand && stuff.type.toLowerCase() !== 'pod kit' && stuff.model === devicesModel));
      }
      searchParams.set('devicesModel', devicesModel);
      setSearchParams(searchParams)
    }
  }, [isDevices, devicesModel, activeModel]);

  useEffect(() => {
    setStuffCount(12);

    if (isModelChanged) {
      searchParams.set("model", activeModel);
      setSearchParams(searchParams);
    }

    if (activeBrand !== "all") {
      setFilteredStuffList(
        stuffList.filter(
          (item) =>
            (item.model === activeModel || activeModel === "all") &&
            item.brand === activeBrand
        )
      );
    }
  }, [activeModel]);

  useEffect(() => {
    if (isThirdFilter && activeFilter === "non-tobacco" &&  activeCategory === "snus") {
      console.log('doing')
      setFilteredStuffList(
        stuffList.filter((item) => {
          if (activeThirdFilter.additionFilter) {
            return (
              item.nicotineContent === activeThirdFilter.nicotineContent &&
              item.title
                .toLowerCase()
                .includes(activeThirdFilter.additionFilter) &&
              item.brand.toLowerCase() === activeThirdFilter.brand &&
              !item.isTobacco
            );
          } else if (activeThirdFilter.isIcedOut) {
            return (
              item.title.toLowerCase().includes("iced out") &&
              item.brand.toLowerCase() === activeThirdFilter.brand &&
              !item.isTobacco
            );
          } else {
            return (
              !item.title.toLowerCase().includes("slim") &&
              item.nicotineContent === activeThirdFilter.nicotineContent &&
              item.brand.toLowerCase() === activeThirdFilter.brand &&
              !item.isTobacco
            );
          }
        })
      );

      searchParams.set("thirdFilter", JSON.stringify(activeThirdFilter));
      setSearchParams(searchParams);
    }
  }, [activeThirdFilter]);

  if (loading) {
    return <Loader3Packs />;
  }

  return (
    <div>
      <Header title="Категории" />
      <Link to="/cart">
        <div className="position-absolute top-0 end-0 cart-svg">
          <CartSvg />
        </div>
      </Link>
      <div className="mt-2 px-3">
        <CategoriesListMemo activeCategory={activeCategory} />
      </div>
      <div className="ms-3 mt-4">
        <GetSwiper
          category={activeCategory}
          filter={activeFilter}
          setActFilter={setActFilter}
          activeBrand={activeBrand}
          brands={brands}
          setActBrand={setActBrand}
          models={models}
          activeModel={activeModel}
          setActModel={setActModel}
          loading={loading}
          isThirdFilter={isThirdFilter}
          thirdFilters={thirdFilters}
          activeThirdFilter={activeThirdFilter}
          setActThirdFilter={setActThirdFilter}
          isDevices={isDevices}
          changeDevices={changeIsDevices}
          changeDevicesModel={changeDevicesModel}
          devicesModel={devicesModel}
        />
      </div>
      <div className="mt-5">
        <p className="px-3 shop-header mb-1">
          {activeCategory === "snus" && "Снюс"}
          {activeCategory === "pods" && "Поды"}
          {activeCategory === "liquids" && "Жидкости"}
          {activeCategory === "devices" && "Устройства"}
        </p>
        <StuffList
          stuffCount={stuffCount}
          stuffs={filteredStuffList.slice(0, stuffCount)}
          filter={activeFilter}
          activeCategory={activeCategory}
          brand={activeBrand}
          model={activeModel}
          from="categories"
        />
      </div>
      <AnchorBasket />
    </div>
  );
};

export default Categories;
