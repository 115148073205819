import React, { useEffect, useState } from "react";
import Header from "../Components/Common/Header";
import { Col, Container, Row } from "react-bootstrap";
import FlexElement from "../Components/UI/FlexElement";
import bagSvg from "../images/icons/Bag.svg";
import { Link, useNavigate } from "react-router-dom";
import fetchData from "../Lib/fetchData";
import Loader3Packs from "./Loaders/Loader3Packs";
import sadMrPackImg from "../images/imgs/sad-mr-pack.png";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const getAllOrders = async () => {
      const ordrs = await fetchData("/order", "GET").then((res) => res.json());
      setOrders(ordrs.reverse());
      setLoading(false);
    };

    getAllOrders();
  }, []);

  if (loading) {
    return <Loader3Packs />;
  }



  if (orders.length === 0) {
    return (
        <Container fluid className="h-100" style={{
          backgroundColor: "var(--background-color)"
        }}>
          <Header title="Заказы" />
          <div
              className="text-center m-auto my-4 h-full d-flex justify-content-center flex-column"
              style={{ height: "70vh" }}
          >
            <div>
              <img src="/pack_hat.png" alt="sad-mr-pack" height={200} />
              <p>Скоро здесь будут ваши заказы</p>
            </div>
          </div>
        </Container>
    );
  }

  return (
    <>
      <Header title="Заказы" />
      <Container fluid className="mt-3">
        <div className="settings-list">
          {orders.map((order) => (
            <Link to={`/order?id=${order._id}`} key={order._id}>
              <Row className="profile-link px-0">
                <Col className="link-img-div col-2 text-center position-relative">
                  <img
                    src={bagSvg}
                    alt="Svg icon"
                    className="link-img position-absolute top-50 start-50 translate-middle"
                    width="20px"
                    height="20px"
                  />
                </Col>
                <Col className="link-text mx-0 px-0 col-6">
                  <span className="link-text-header">{`Заказ #${order._id.slice(
                    order._id.length - 6,
                  )}`}</span>
                  {`От ${String(new Date(order.createdAt).getDate()).padStart(
                    2,
                    "0",
                  )}.${String(
                    new Date(order.createdAt).getMonth() + 1,
                  ).padStart(2, "0")}.${String(
                    new Date(order.createdAt).getFullYear(),
                  ).padStart(2, "0")}` && (
                    <div className="link-text-desc">
                      <span>{`От ${String(
                        new Date(order.createdAt).getDate(),
                      ).padStart(2, "0")}.${String(
                        new Date(order.createdAt).getMonth() + 1,
                      ).padStart(2, "0")}.${String(
                        new Date(order.createdAt).getFullYear(),
                      ).padStart(2, "0")}`}</span>
                      <div
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "0.5rem",
                        }}
                      >
                        {order.orderedStuff.map((el, index) => {
                          return (
                            index < 3 && (
                              <img
                                src={`${process.env.REACT_APP_AWS_URL}/images/${el.image}`}
                                alt=""
                                width={20}
                                height={20}
                                key={index}
                                style={{borderRadius: '100%'}}
                              />
                            )
                          );
                        })}
                      </div>
                    </div>
                  )}
                </Col>
                <Col className="position-relative">
                  <div className="position-absolute top-50 left-50">
                    {order.status[order.status.length - 1].status ===
                      "processing" && (
                      <span className="text-primary">В обработке</span>
                    )}
                    {order.status[order.status.length - 1].status ===
                      "active" && <span className="text-success">Принят</span>}
                    {order.status[order.status.length - 1].status ===
                      "out_for_delivery" && (
                      <span style={{ color: "#FF8A00" }}>В доставке</span>
                    )}
                    {order.status[order.status.length - 1].status ===
                      "delivered" && (
                      <span className="text-warning">Доставлен</span>
                    )}
                    {order.status[order.status.length - 1].status ===
                      "cancelled" && (
                      <span className="text-danger">Отменён</span>
                    )}
                  </div>
                </Col>
              </Row>
            </Link>
          ))}
        </div>
      </Container>
    </>
  );
};

export default Orders;
