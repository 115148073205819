import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import tempImg from "../../images/temp-img.png";
import fetchData from "../../Lib/fetchData";
import { Link, useNavigate } from "react-router-dom";
import { HistoryContext } from "../../Context/HistoryContext";

const OftenOrders = () => {
  const [stuff, setStuff] = useState([]);
  const navigate = useNavigate();
  const { setHistoryPath } = useContext(HistoryContext);

  useEffect(() => {
    const getOftenOrderStuff = async () => {
      const res = await fetchData("/stuff/frequently_ordered", "GET").then(
        (res) => res.json()
      );
      setStuff(res.map((v) => v.stuff[0]).filter(v => typeof v !== 'undefined'));
    };

    getOftenOrderStuff();
  }, []);

  if (stuff.length !== 0) {
    return (
      <div className="mt-4 px-0">
        <p className="shop-header mb-2">Часто заказываете</p>
        <Row className="often-order-div p-2 text-center">
          {stuff.length !== 0 &&
            stuff.map((stuff) => (
              <Col
                className="col-4 p-0"
                key={stuff._id}
                onClick={() => {
                  setHistoryPath("/shop");
                  localStorage.setItem("path", '/shop');
                  navigate(`/stuff?id=${stuff._id}`);
                }}
              >
                <div
                  className="stuff-often-orders-div"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={`${process.env.REACT_APP_AWS_URL}/images/${stuff.image}`}
                    alt=""
                    width="92%"
                    className="stuff-card-img"
                  />
                </div>
              </Col>
            ))}
        </Row>
      </div>
    );
  }
};

export default OftenOrders;
