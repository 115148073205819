import React from "react";

const CheckoutOrder = ({ price, promocodeUsed, paymentType }) => {
    return (
        <div className="checkout">
            <div className="checkout-elem">
                <span>Сумма заказа:</span>
                {/* eslint-disable-next-line no-undef */}
                <span>{paymentType === 'usdt' ? `${fx(price.originalPrice).from("RUB").to("USD").toFixed(2)} $` : `${price.originalPrice} ₽`}</span>
            </div>
            <div className="checkout-elem">
                <span>Доставка:</span>
                {promocodeUsed === "NGSFREE" ? (
                        <span><s>{paymentType === 'usdt'
                            // eslint-disable-next-line no-undef
                            ? `${fx(price.discountByPromo).from("RUB").to("USD").toFixed(2)} $`
                            : `${price.discountByPromo} ₽`}</s></span>) :
                    // eslint-disable-next-line no-undef
                    <span>{paymentType !== 'usdt' ? `${price.deliveryPrice} ₽` : `${fx(price.deliveryPrice).from("RUB").to("USD").toFixed(2)} $`}</span>}
            </div>
            {
                promocodeUsed === "NGS10" && (
                    <div className="checkout-elem">
                        <span>Промокод:</span>
                        {/* eslint-disable-next-line no-undef */}
                        <span>{paymentType === 'usdt' ? `-${fx(price.discountByPromo).from("RUB").to("USD").toFixed(2)} $` : `-${price.discountByPromo} ₽`}</span>
                    </div>
                )
            }
            {price.discount !== 0 && (
                <>
                    <div className="checkout-elem">
                        <span>Скидка:</span>
                        {/* eslint-disable-next-line no-undef */}
                        <span style={{textDecoration: 'none'}}>{paymentType === 'usdt' ? `-${fx(price.discountByQuantity).from("RUB").to("USD").toFixed(2)} $` : `-${price.discountByQuantity} ₽`}</span>
                    </div>
                </>
            )}

            <div className="checkout-elem checkout-elem-total">
                <span>Итого:</span>
                {/* eslint-disable-next-line no-undef */}
                <span>{paymentType === 'usdt' ? `${fx(price.totalPrice).from("RUB").to("USD").toFixed(2)} $` : `${price.totalPrice} ₽`}</span>
            </div>
        </div>
    );
};

export default CheckoutOrder;
